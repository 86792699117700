$editedColour: #cc7408; // TODO: Add to colours file once theming is addressed.

.QuestionField {
  position: relative;

  &__heading {
    padding: 20px 0 0 12px;
  }

  &__input {
    border: 2px solid transparent;
    border-radius: 3px 3px 0 3px;
  }

  &__reset {
    opacity: 0;
    position: absolute;
    border-left: 2px solid $editedColour;
    border-right: 2px solid $editedColour;
    border-bottom: 2px solid $editedColour;
    background-color: white;
    font-weight: bold;
    border-radius: 3px;
    color: $editedColour;
    padding: 8px 15px;
    bottom: -38px;
    right: 0;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      width: 138px;
      height: 10px;
      top: -2px;
      z-index: 1;
      background-color: white;
      left: 0px;
    }
  }

  &--edited {
    .QuestionField__input {
      border-color: $editedColour;
    }

    .QuestionField__reset {
      opacity: 1;
    }
  }
}
