.ConfirmDialog {
  &__copy {
    margin: 0 !important;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 20px 10px 20px;
  }

  &__warning {
    align-self: center;
  }

  &__confirm-btn {
    margin-right: 20px !important;
  }
}
