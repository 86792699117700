.Dialog {
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;

  &__title {
    text-align: center;
    padding: 0 24px;
    font-size: 24px;
  }

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
